<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the rms speed,
        <stemble-latex content="$u,$" />
        of a molecule of
        <chemical-latex :content="molecule" />
        at
        <number-value :value="temp" unit="^\circ\text{C.}" />
      </p>

      <calculation-input
        v-model="inputs.rmsSpeed"
        prepend-text="$u:$"
        append-text="$\text{m/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question397',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rmsSpeed: null,
      },
    };
  },
  computed: {
    molVersion() {
      return this.taskState.getValueBySymbol('molVersion').content;
    },
    versionData() {
      switch (this.molVersion.value) {
        case 1:
          return {
            molMass: 4.0026,
            chemicalFormula: 'He',
          };
        case 2:
          return {
            molMass: 39.948,
            chemicalFormula: 'Ar',
          };
        case 3:
          return {
            molMass: 2.016,
            chemicalFormula: 'H2',
          };
        case 4:
          return {
            molMass: 28.014,
            chemicalFormula: 'N2',
          };
        case 5:
          return {
            molMass: 31.998,
            chemicalFormula: 'O2',
          };
        default:
          return {
            molMass: 44.009,
            chemicalFormula: 'CO2',
          };
      }
    },
    molarMass() {
      return this.versionData.molMass;
    },
    molecule() {
      return this.versionData.chemicalFormula;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
  },
};
</script>
